<template>
  <div class="page-main-bg half-circle-bg">
    <v-container class="main-container team-container">
      <div v-if="isPageLoading" class="pt-15">
        <Loader v-bind="loaderProps" />
      </div>
      <template v-else>
        <!------------------------------------------->
        <!-------------Breadcrumbs-------------------->
        <!-------------------------------------------->
        <tool-bar
          class="pl-3"
          v-bind="toolBarProps"
          @setDate="setDate"
          @toggleCards="toggleShowStats"
        />
        <v-container>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <!------------------------------------------->
              <!--Realestate status and available actions-->
              <!------------------------------------------->
              <ClientDetailsToolbar
                v-bind="clientToolbarProps"
                @addNewUnit="extraUnitsState = true"
                @completeUnitData="singleUnitDialogState = true"
                @change-show-hectar="changeShowHectar"
              />
              <!------------------------------------------->
              <!--------Rented realestate sttistics-------->
              <!------------------------------------------->
              <ClientDetailsTiles
                v-if="
                  (isPartiallyRented || realEstateStatusEn === 'rented') &&
                  $hasPermission('statistics', 'statistics', 'list')
                "
                v-show="cardsInfoState"
                v-bind="clientDetailsTilesProps"
              />
            </v-col>
            <v-col md="7" sm="12" cols="12">
              <!------------------------------------------->
              <!-------------Realestate details------------->
              <!-------------------------------------------->
              <ClientDetailsInfoCard
                style="height: 100%"
                :realEstate="clientDetailsInfoCardProps?.realEstate"
                @dialogState="openDialogState"
                @completeUnitData="singleUnitDialogState = true"
              />
            </v-col>
            <v-col
              md="5"
              sm="12"
              cols="12"
              class="details__list__tab properties__cards"
            >
              <!------------------------------------------->
              <!-------------Realestate owners info--------->
              <!-------------------------------------------->
              <DetailsTabs
                v-bind="detailsTabsProps"
                @openSelectModel="bankSelectModelDialogState = true"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="file__listbond__tab properties__cards"
            >
              <!------------------------------------------->
              <!-------------Realestate bonds-------------->
              <!-------------------------------------------->
              <FileListCard
                v-bind="listBondsProps"
                @showAddBondModal="showAddBondModal = true"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="file__listcontract__tab properties__cards"
            >
              <!------------------------------------------->
              <!-------------Realestate contracts---------->
              <!------------------------------------------->
              <FileListCard
                v-bind="listContractsProps"
                @showAddContractModal="openAddContractModal"
              />
            </v-col>
            <v-col cols="12" md="4" class="file__list__tab properties__cards">
              <!--Deposit and expenses tabs-->
              <v-card
                class="d-flex flex-column document__bond__contract__list__cards mb-3"
                height="165"
              >
                <v-card-title class="px-5 py-0">
                  <v-tabs
                    class="tabs-container"
                    color="black"
                    align-with-title
                    right
                    v-model="tab"
                  >
                    <v-tabs-slider color="black"></v-tabs-slider>
                    <v-tab
                      v-for="tab in tabs"
                      :key="tab.component"
                      color="#9F9FAC"
                      class="font-16 mr-0"
                    >
                      {{ tab.title }}
                    </v-tab>
                  </v-tabs>
                </v-card-title>

                <v-card-text class="mt-4 px-0 pb-1">
                  <v-tabs-items v-model="tab">
                    <v-tab-item
                      v-for="tab in tabs"
                      :key="tab.component"
                      :eager="true"
                    >
                      <component
                        :is="tab.component"
                        v-bind="generateTabProps(tab)"
                        @showExpensesLimitModal="changeExpensesModalState(true)"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>

              <!--Realestate documents-->
              <FileListCard
                v-bind="listDocumentsProps"
                @documentFileUploader="openDocumentsInput"
                @delete-document="deleteDocument"
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="12"
              class="client__listdetails__tab properties__cards"
            >
              <!------------------------------------------->
              <!--Building or compound interanl extra unit-->
              <!-------------------------------------------->
              <ClientDetailsRealEstateList
                v-if="hasUnits"
                cols="3"
                :PropertyId="realEstate._id"
                :real-estate-type="realEstateType"
                :type="false"
                :isFromDetails="true"
                ref="innerUnits"
                @realEstates="realEstates = $event"
              />
              <!------------------------------------------->
              <!-------------Realestate images slider------>
              <!-------------------------------------------->
              <DetailsSlider
                :property-data="realEstate"
                @update-property-image="updatePropertyImages"
              />
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <!------------------------------------------->
              <!-------------Realestate blueprint---------->
              <!------------------------------------------->
              <BlueprintDetails
                :property-data="realEstate"
                @set-blueprint-image="setBlueprintImage"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-container>
    <!------------------------------------------->
    <!-------------Edit realestate modal-------->
    <!-------------------------------------------->
    <unit-modal
      v-if="dialogState"
      :propertyInfo="realEstate"
      :dialogState="dialogState"
      @changeDialogState="dialogState = $event"
      @closed-property-dialog="closeAddDialog"
      @refreshPropertiesList="getRealEstateDetails"
    />
    <!-------------------------------------------->
    <!----Add extra units to realestate modal----->
    <!-------------------------------------------->
    <addExtraUnits
      v-if="extraUnitsState"
      :extraUnitsState="extraUnitsState"
      :buildingType="buildingType"
      :realEstatePurpose="realEstatePurpose"
      @closeModel="closeAddExtraUnitsModal"
      @addUnits="addUnits"
      @changeExtraUnitsState="extraUnitsState"
    />
    <!-------------------------------------------->
    <!---------Complete unit details modal-------->
    <!-------------------------------------------->
    <SingleUnitModel
      v-if="singleUnitDialogState"
      :dialogState="singleUnitDialogState"
      :propertyInfo="realEstate"
      :employees="employees"
      @changeDialogState="singleUnitDialogState"
      @closeDialog="closeAddDialog"
      @refresh-properties-list="getRealEstateDetails"
    />
    <!------------------------------------------->
    <!-------------Add contract modal-------->
    <!-------------------------------------------->
    <addContractNewModal
      :contractModalState="showAddContractModal"
      :contractIntervals="contractIntervals"
      @closeContractModal="showAddContractModal = $event"
      @refreshContracts="closeAddDialog"
      :defaultData="defaultContractModalData"
      v-if="showAddContractModal"
    />
    <!------------------------------------------->
    <!-------------Add bond modal---------------->
    <!------------------------------------------->
    <AddBondModal
      v-if="showAddBondModal"
      @showAddBondModal="showAddBondModal = $event"
      v-bind="addBondProps"
      @closed-bond-dialog="closeAddDialog"
    />

    <ActivityLogsSideDetails
      v-if="showActivityLogs"
      v-bind="{ showActivityLogs, type: 'realestate' }"
      @closeActivityLogs="closeActivityLogs"
    />

    <!------------------------------------------->
    <!---------Expense Limit Modal--------------->
    <!------------------------------------------->
    <ExpensesLimitModal
      v-if="expensesLimitModal.dialogState"
      v-bind="expensesLimitModalProps"
      @closeExpensesLimitModal="changeExpensesModalState(false)"
      @saveExpensesLimit="saveExpensesLimit"
    />

    <!------------------------------------------->
    <!---------Bank Acounts Select Model--------->
    <!------------------------------------------->
    <bankAcountsSelectModel
      v-if="bankSelectModelDialogState"
      :dialogState="bankSelectModelDialogState"
      :realEstateBankAccounts="realEstateBankAccounts"
      :realEstate="realEstate"
      @changeDialogState="bankSelectModelDialogState = $event"
      @refreshBankAccounts="getRealestateBankAccounts"
    />

    <!--Documents input-->
    <form enctype="multipart/form-data" v-show="false">
      <v-file-input
        ref="documentsInput"
        truncate-length="15"
        prepend-icon="mdi-plus"
        hide-input
        @change="addDocument"
      >
      </v-file-input>
    </form>
  </div>
</template>
<script>
import ClientDetailsInfoCard from '@/components/details/ClientDetailsInfoCard'
import FileListCard from '@/components/details/FileListCard'
import expenseLimit from '../components/details/expenseLimit.vue'
import ClientDetailsTiles from '@/components/details/ClientDetailsTiles'
import ClientDetailsRealEstateList from '@/components/details/ClientDetailsRealEstateList'
import ClientDetailsToolbar from '@/components/details/ClientDetailsToolbar'
import DetailsSlider from '../components/details/DetailsSlider'
import DetailsTabs from '../components/details/detailsTabs'
import {
  realEstateService,
  contractsService,
  bondsService,
  EnvService
} from '@/services'
import { mapMutations, mapState, mapGetters } from 'vuex'
import SingleUnitModel from '@/components/add-unit/unitModelTabs/singleUnitModel'
import BlueprintDetails from '../components/details/BlueprintDetails'
import addContractNewModal from '@/components/add-contract/contractModal'
import Loader from '@/components/helper/loader.vue'
import ReceivedDeposit from '@/components/details/ReceivedDeposit'
import ToolBar from '@/components/listing/ToolBar'

export default {
  name: 'PropertyDetails',
  components: {
    AddBondModal: () => import('@/components/modals/AddBondModal.vue'),
    unitModal: () => import('@/components/add-unit/unitModal'),
    ActivityLogsSideDetails: () =>
      import('@/components/ActivityLogs/ActivityLogsSideDetails'),
    addExtraUnits: () =>
      import('@/components/add-unit/unitModelTabs/addExtraUnits'),
    DetailsTabs,
    DetailsSlider,
    ClientDetailsToolbar,
    ClientDetailsRealEstateList,
    ClientDetailsTiles,
    FileListCard,
    ClientDetailsInfoCard,
    SingleUnitModel,
    BlueprintDetails,
    addContractNewModal,
    expenseLimit,
    ExpensesLimitModal: () =>
      import('@/components/modals/ExpensesLimitModal.vue'),
    bankAcountsSelectModel: () =>
      import('@/components/core/bankAcountsSelectModel.vue'),
    Loader,
    ReceivedDeposit,
    ToolBar
  },
  data: () => {
    return {
      bankSelectModelDialogState: false,
      realEstateBankAccounts: [],
      cardsInfoState: true,
      datesQuery: {
        startDate: '',
        endDate: ''
      },
      isPageLoading: false,
      extraUnitsState: false,
      showAddBondModal: false,
      showAddContractModal: false,
      editedBond: {},
      singleUnitDialogState: false,
      realEstate: null,
      bonds: [],
      contracts: [],
      realEstates: [],
      tabsTitles: ['المالك', ' المستأجرين', 'المسؤولين ', 'الحسابات البنكية'],
      dialogState: false,
      parent: '',
      addNewOwner: false,
      bondsAvailableTypes: [],
      employees: [],
      allDocuments: [],
      parentProperty: [],
      showActivityLogs: false,
      responsiveActions: [
        {
          name: 'سجل نشاطات العقار',
          color: 'teal',
          icon: require('@/assets/img/activity-log-icons/purple-activity-icon.svg'),
          imgResponsive: require('@/assets/img/activity-log-icons/purple-activity-icon.svg'),
          method: 'showActivityLogs'
        },
        {
          name: 'إضافة عقد',
          icon: require('@/assets/img/svgs/purple-contracts.svg'),
          method: 'showAddContractModal',
          title: 'contracts',
          needPermession: true
        },
        {
          name: 'إضافة سند',
          icon: require('@/assets/img/svgs/purple-bonds-icon.svg'),
          method: 'showAddBondModal',
          title: 'bonds',
          needPermession: true
        }
      ],
      contractIntervals: [],
      expensesLimitModal: {
        dialogState: false,
        isSaveProcessFailed: false
      },
      tabs: [
        { title: 'مصروفات الصيانة', component: 'expenseLimit' },
        { title: 'العهُدة المستلمة', component: 'ReceivedDeposit' }
      ],
      tab: 0,
      clientRoles: {
        renter: 'tenet',
        owner: 'owner'
      }
    }
  },
  async created() {
    this.$emit('updateInformativeTip', this.$route.meta?.informativeTip)
    await this.getRealEstateDetails()
    await this.getBondsTypes()
    this.loadEmployees()
    this.getContractIntervals()
    await this.getRealestateBankAccounts()
  },
  watch: {
    '$route.params': {
      async handler() {
        await this.getRealEstateDetails()
      }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user', 'customerDefaultRole']),
    ...mapGetters('accountState', ['isCustomer']),
    actions() {
      return [
        {
          name: 'تفعيل الإشعارات',
          method: 'toggleNotificationStatus',
          isSwitchBtn: true,
          value: 'notificationStatus',
          toolTip: 'قم بتفعيل الإشعارات من الإعدادات أولا',
          disabled: 'isGlobalNotification'
        }
      ]
    },
    isEditBankAllowed() {
      return (
        !this.realEstate?.parentId?.length && this.hasListBankAccountsPermission
      )
    },
    hasListBankAccountsPermission() {
      return !!this.$hasPermission('users', 'bankAccountsSettings', 'list')
    },
    listContractPermission() {
      return !!this.$hasPermission('properties', 'contracts', 'list')
    },
    listBondPermission() {
      return !!this.$hasPermission('properties', 'bonds', 'list')
    },
    clientDetailsTilesProps() {
      return {
        contractsResponse: this.contracts.length,
        realestateOwner: this.realEstate.owner._id,
        typeName: this.realEstate.type.nameEn,
        isCustomer: this.isCustomer,
        datesQuery: {
          startDate:
            this.datesQuery?.startDate ||
            new Date(new Date().setMonth(new Date().getMonth() - 1)),
          endDate: this.datesQuery?.endDate || new Date()
        }
      }
    },
    toolBarProps() {
      return {
        breadcrumbs: this.breadcrumbs,
        showDateFilter: !!(
          this.isPartiallyRented || this.realEstateStatusEn === 'rented'
        ),
        title: this.realEstate.name,
        cardsInfoState: this.cardsInfoState
      }
    },
    addBondProps() {
      return {
        dialogState: this.showAddBondModal,
        editedBond: this.editedBond,
        defaultValues: {
          realEstate: this.parentProperty?._id
            ? this.parentProperty
            : this.realEstate,
          unit: this.parentProperty?._id ? this.realEstate : {},
          setClickedTag: 'owner'
        }
      }
    },
    isEnvOwner() {
      return (
        !!this.currentEnv?.isOwner ||
        !!(this.currentEnv?.employeeRole === 'owner')
      )
    },
    defaultContractModalData() {
      return this.realEstate?.rentContract ||
        this.realEstate?.rentedUnitsCount > 0
        ? this.realEstate?.owner
        : this.realEstate
    },
    clientDetailsInfoCardProps() {
      return {
        realEstate: {
          completePercentage: this.parentProperty.completePercentage,
          ...this.realEstate,
          title: 'معلومات العقار'
        },
        isEnvOwner: this.isEnvOwner,
        isCustomer: this.isCustomer
      }
    },
    loaderProps() {
      return {
        laoderClasses: 'mx-auto',
        type: 'list-item-avatar-three-line, divider, list-item-two-line, divider, table-tfoot',
        numberOfLines: 8
      }
    },
    propertyId() {
      return this.$route.params.id || undefined
    },
    contractsRequestPayload() {
      const payload = {
        realEstate: this.propertyId,
        ...(this.isCustomer && {
          users: this.user?._id,
          userType: this.clientRoles[this.customerDefaultRole]
        })
      }
      return payload
    },
    bondsRequestPayload() {
      const payload = {
        realEstate: this.propertyId,
        ...(this.isCustomer && {
          user: this.user?._id,
          userType: this.clientRoles[this.customerDefaultRole]
        })
      }
      return payload
    },
    HectarBtn() {
      const realEstateHectarLink = {
        name: 'اعرض في هكتار',
        color: 'green',
        img: require('@/assets/img/svgs/link-icon-white.svg'),
        imgResponsive: require('@/assets/img/svgs/link-icon.svg'),
        method: 'openRealEstateHectarLink'
      }
      return realEstateHectarLink
    },
    parentPropertyBreadCrumbs() {
      return [
        {
          text: ' قائمة العقارات',
          disabled: false,
          exact: true,
          link: true,
          to: {
            name: 'properties',
            params: {
              addNewProperty: 0
            }
          }
        },
        {
          text: this.parentProperty?.propertyName,
          disabeled: false,
          exact: true,
          link: true,
          to: {
            path: `/dashboard/property-details/${
              this.parentProperty?._id
            }?parent=${
              this.parentProperty?.parentId
                ? this.parentProperty?.parentId
                : this.parentProperty?._id
            }`
          }
        },
        {
          text: this.realEstate?.propertyName,
          disabled: true
        }
      ]
    },
    breadcrumbs() {
      return [
        {
          text: ' قائمة العقارات',
          disabled: false,
          exact: true,
          link: true,
          to: {
            name: 'properties',
            params: {
              addNewProperty: 0
            }
          }
        },
        {
          text: this.realEstate?.propertyName,
          disabled: true
        }
      ]
    },
    tabsMembersData() {
      return [
        [this.realEstate?.owner] ?? [
          {
            name: 'لا يوجد مالك لهذا العقار'
          }
        ],
        this.realEstate?.tenets ?? [
          {
            name: 'لا يوجد مستأجر'
          }
        ],
        this.realEstate?.moderators ?? [
          {
            name: 'لا يوجد مسؤولين'
          }
        ]
      ].filter(Boolean)
    },
    parentId() {
      return this.$route.query?.parent
    },
    realEstateNameAR() {
      return this.realEstate?.propertyName ?? ''
    },
    realEstateType() {
      return this.realEstate?.type.nameAr ?? ''
    },
    realEstateStatusEn() {
      return this.realEstate?.status?.nameEn ?? ''
    },
    isPartiallyRented() {
      return this.realEstate?.rentedUnitsCount ?? 0
    },
    buildingType() {
      return this.realEstate?.type ?? {}
    },
    propertyToSend() {
      const dataToSend = {
        parentId: this.realEstate?._id
      }
      return dataToSend
    },
    clientToolbarProps() {
      return {
        contractsResponse: this.contracts.length,
        realEstatesResponse: this.realEstates.length,
        actions: this.actions,
        toolbarInfo: {
          ...this.realEstate,
          completePercentage: this.parentProperty.completePercentage
        },
        responsiveActions: this.responsiveActions,
        showHideHectarAction: this.showHideHectarAction
      }
    },
    detailsTabsProps() {
      return {
        files: this.tabsMembersData,
        tabs: this.tabsTitles,
        realEstateBankAccounts: this.realEstateBankAccounts,
        realEstateBankAccountsLoading: this.realEstateBankAccountsLoading,
        isEditBankAllowed: this.isEditBankAllowed
      }
    },
    listBondsProps() {
      return {
        files: this.bonds,
        subTitle: 'bond',
        link: {
          name: 'إظهار كل سندات العقار',
          url: '/dashboard/bonds',
          realEstate: {
            _id: this.realEstate?._id,
            propertyName: this.realEstate?.propertyName
          }
        },
        hasAddPermission: this.$hasPermission('properties', 'bonds', 'add'),
        modal: 'showAddBondModal',
        componentName: 'ClientDetailsFileList',
        noData: this.listBondPermission
          ? 'لا توجد سندات مرسله لهذا العقار'
          : 'ليس لديك صلاحية لتصفح السندات',
        title: 'قائمة السندات'
      }
    },
    listContractsProps() {
      return {
        files: this.contracts,
        subTitle: 'contract',
        link: {
          name: 'إظهار كل عقود العقار',
          url: '/dashboard/contracts',
          realEstate: {
            _id: this.realEstate?._id,
            propertyName: this.realEstate?.propertyName
          }
        },
        hasAddPermission: this.$hasPermission('properties', 'contracts', 'add'),
        componentName: 'ClientDetailsFileList',
        noData: this.listContractPermission
          ? 'لا توجد عقود مرسله لهذا العقار'
          : 'ليس لديك صلاحيه لتصفح العقود',
        title: 'قائمة العقود',
        modal: 'showAddContractModal'
      }
    },
    listDocumentsProps() {
      return {
        files: this.allDocuments,
        showDocuments: true,
        hasAddPermission: true,
        componentName: 'ClientDetailsFileList',
        noData: 'لا توجد وثائق مرسله لهذا العقار',
        title: 'قائمة الوثائق',
        modal: 'documentFileUploader',
        class: 'document__list--card'
      }
    },
    isShownInHectar() {
      return this.realEstate?.showInHectar
    },
    showHideHectarAction() {
      return {
        name: this.isShownInHectar ? 'إخفاء من هكتار' : 'إظهار في هكتار',
        icon: this.isShownInHectar
          ? require('@/assets/img/svgs/purple-eye-closed.svg')
          : require('@/assets/img/svgs/purple-eye.svg'),
        method: 'changeShowInHectar'
      }
    },
    hasUnits() {
      return !!this.realEstate?.unitsCount
    },
    realEstatePurpose() {
      return this.realEstate?.purpose?.nameEn
    },
    isRealEstateHasUnits() {
      return (
        this.realEstateType === 'عمارة' ||
        this.realEstateType === 'مجمع' ||
        this.realEstateType === 'برج' ||
        this.realEstateType === 'مبنى' ||
        this.realEstateType === 'فيلا'
      )
    },
    realEstateExpenseLimit() {
      return this.realEstate?.expenseLimit ?? null
    },
    expensesLimitModalProps() {
      return {
        dialogState: this.expensesLimitModal.dialogState,
        isSaveExpenseLimitFailed: this.expensesLimitModal.isSaveProcessFailed,
        expenseLimit: this.realEstateExpenseLimit
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    async getRealestateBankAccounts() {
      this.realEstateBankAccountsLoading = true
      try {
        const { data } = await EnvService.getRealestateBankAccounts({
          realEstateId: this.realEstate?._id,
          parentId: this.realEstate?.parentId,
          environmentId: this.currentEnv?._id
        })
        this.realEstateBankAccounts =
          data.realestateBankAccounts?.bankAccounts ?? []
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل حسابات العقار',
          color: 'error'
        })
      } finally {
        this.realEstateBankAccountsLoading = false
      }
    },
    toggleShowStats() {
      this.cardsInfoState = !this.cardsInfoState
    },
    setDate({ dateType, date }) {
      this.cardsInfoState = true
      this.datesQuery[`${dateType}`] = date
      this.refreshState += 1
    },
    async getContractIntervals() {
      try {
        const Response = await contractsService.getContractIntervals()
        this.contractIntervals = Response.data.intervals
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل فترات العقد, برجاء المحاوله لاحقا',
          color: 'error'
        })
      }
    },
    async getRealEstateDetails() {
      this.isPageLoading = true
      try {
        const [
          realEstateResponse,
          contractsResponse,
          bondsResponse,
          documentsResponse
        ] = await Promise.all([
          await realEstateService.getSingleProperty(this.propertyId),
          await contractsService.getContracts(this.contractsRequestPayload),
          await bondsService.getAllBonds(this.bondsRequestPayload),
          await realEstateService.getDocuments(this.propertyId)
        ])
        this.realEstate = realEstateResponse?.data?.realEstate ?? []
        this.contracts = contractsResponse?.data?.contracts ?? []
        this.allDocuments = documentsResponse?.data?.attachments ?? []
        this.bonds = bondsResponse.data.bonds
        if (this.realEstate?.parentId) {
          await realEstateService
            .getSingleProperty(this.realEstate.parentId)
            .then((res) => {
              this.parentProperty = res.data.realEstate
            })
        } else {
          this.parentProperty = {}
        }
        this.addHectarLinkAction()
        this.setActionsPerConditions()
      } catch {
        this.addNotification({
          text: 'خطأ في استرجاع بيانات العقار',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    async getBondsTypes() {
      try {
        const bondTypes = await bondsService.bondsType()
        this.bondsAvailableTypes = bondTypes.data.bondsType
      } catch (err) {
        this.addNotification({
          text: err,
          color: 'error'
        })
      }
    },
    openDialogState(val, addNewOwner) {
      this.dialogState = val
      this.addNewOwner = !!addNewOwner
    },
    async closeAddDialog() {
      this.dialogState = false
      this.singleUnitDialogState = false
      this.showAddContractModal = false
      this.showAddBondModal = false
      await this.getRealEstateDetails()
    },
    loadEmployees() {
      EnvService.getAllEmployee(this.currentEnv._id, '&registered=1')
        .then((res) => {
          this.employees = res.data.users
        })
        .catch((err) => console.log('err', err))
    },
    openAddContractModal() {
      this.showAddContractModal = true
    },
    openAddBondModal() {
      this.showAddBondModal = true
    },
    async addUnits(unitsData) {
      const reqBody = {
        ...unitsData,
        ...this.propertyToSend,
        environment: this.currentEnv._id
      }
      try {
        await realEstateService.addUnits(reqBody)
        this.addNotification({
          text: `تم إضافه وحداتك بنجاح`,
          color: 'success'
        })
        this.extraUnitsState = false
        await this.getRealEstateDetails()
        this.$refs.innerUnits.getOwnerRealEstates()
      } catch (error) {
        this.addNotification({
          text:
            error.response?.data?.errors?.[0] ||
            error.message ||
            'something went wrong',
          color: 'error'
        })
      }
    },
    openDocumentsInput() {
      this.$refs.documentsInput.$refs.input.click()
    },
    async addDocument(file) {
      try {
        const fd = new FormData()
        fd.append('attachment', file, file.name)
        fd.append('entityId', this.realEstate._id)
        fd.append('envId', this.currentEnv._id)
        const { data } = await realEstateService.addDocument(fd)
        const attachment = data.file
        this.allDocuments.push(attachment)
        this.addNotification({
          text: 'تم إضافة الوثيقة بنجاح',
          color: 'success'
        })
      } catch (error) {
        if (error.response.data.error === 'feature not allowed') {
          this.addNotification({
            text: 'اضافة الوثائق غير متاحه في الباقة الحالية، يجب الترقية لتتمكن من اضافة الوثائق',
            color: 'error'
          })
        } else {
          this.addNotification({
            text: 'لم يتم إضافة الوثيقة يرجى المحاولة مره اخرى',
            color: 'error'
          })
        }
      }
    },
    async toggleHectarVisibility() {
      const accountId = {
        account: {
          id: this.realEstate.account?.id
        }
      }
      try {
        const { data } = await realEstateService.toggleVisibility({
          realEstateId: this.realEstate._id,
          accountId
        })
        this.realEstate.showInHectar = data.showInHectar
        this.addHectarLinkAction()
        this.addNotification({
          text: this.isShownInHectar
            ? 'تم إظهار العقار في هكتار'
            : 'تم إخفاء العقار من هكتار',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء المحاوله مجددا',
          color: 'error'
        })
      }
    },
    openActivityLogsModal() {
      this.showActivityLogs = true
    },
    changeShowHectar() {
      if (['sold', 'rented'].includes(this.realEstateStatusEn)) {
        this.addNotification({
          text: 'لايمكن اظهار العقارات المباعة والمستأجرة في هكتار',
          color: 'error'
        })
      } else {
        this.toggleHectarVisibility()
      }
    },
    setActionsPerConditions() {
      const addUnitAction = {
        name: 'إضافة وحدات',
        icon: require('@/assets/img/svgs/purple-house.svg'),
        method: 'addNewUnit'
      }
      if (
        this.isRealEstateHasUnits &&
        this.$hasPermission('realestates', 'realestates', 'add')
      ) {
        if (this.responsiveActions[0].name === 'إضافة وحدات') {
          return
        }
        this.responsiveActions.unshift(addUnitAction)
      } else {
        this.responsiveActions = this.responsiveActions.filter((action) => {
          return action.method !== 'addNewUnit'
        })
      }
    },
    async deleteDocument(fileDetails) {
      try {
        await realEstateService.deleteDocument(fileDetails.fileId)
        this.allDocuments.splice(fileDetails.fileIndex, 1)
        this.addNotification({
          text: 'تم حذف الوثيقه بنجاح',
          color: 'success'
        })
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء المحاوله مرة اخرى',
          color: 'error'
        })
      }
    },
    updatePropertyImages(images) {
      this.realEstate.images = images
    },
    setBlueprintImage(blueprintImage) {
      this.realEstate.bluePrint[0].image = blueprintImage.image
      this.realEstate.bluePrint[0].key = blueprintImage.key
    },
    closeActivityLogs() {
      this.showActivityLogs = false
    },
    addHectarLinkAction() {
      const isHectarBtnExisted = this.actions.find(
        (action) => action.method === 'openRealEstateHectarLink'
      )
      if (
        this.isShownInHectar &&
        !isHectarBtnExisted &&
        this.$hasPermission('realestates', 'realestates', 'add')
      ) {
        this.actions.unshift(this.HectarBtn)
      } else if (!this.isShownInHectar) {
        this.actions = this.actions.filter((action) => {
          return action.method !== 'openRealEstateHectarLink'
        })
      }
    },
    closeAddExtraUnitsModal() {
      this.extraUnitsState = false
    },
    changeExpensesModalState(val) {
      this.$set(this.expensesLimitModal, 'dialogState', val)
    },
    async saveExpensesLimit(_expenseLimit) {
      this.$set(this.expensesLimitModal, 'isSaveProcessFailed', false)
      try {
        const body = {
          _id: this.realEstate._id,
          environment: this.currentEnv._id,
          expenseLimit: _expenseLimit
        }
        await realEstateService.updateRealEstate(body)
        this.$set(this.realEstate, 'expenseLimit', _expenseLimit)
        this.addNotification({
          text: 'تم تعديل الحد الأقصى للمستحقات بنجاح',
          color: 'success'
        })
        this.changeExpensesModalState(false)
      } catch {
        this.$set(this.expensesLimitModal, 'isSaveProcessFailed', true)
        this.addNotification({
          text: 'خطأ في تعديل الحد الأقصى للمستحقات، يرجى المحاولة مجددا',
          color: 'error'
        })
      }
    },
    generateTabProps(tab) {
      let requiredProps = {}
      if (tab.component === 'expenseLimit') {
        requiredProps = {
          hasAddPermission: this.$hasPermission(
            'properties',
            'contracts',
            'add'
          ),
          expenseLimit: this.realEstateExpenseLimit
        }
      } else {
        requiredProps.realEstateId = this.realEstate?._id
      }
      return requiredProps
    }
  },
  metaInfo() {
    return {
      title: 'تفاصيل العقار'
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/material-dashboard/_properties.scss';
@import '@/styles/material-dashboard/_team.scss';
.page-main-bg {
  background-color: #f7f7f7;
}
.v-breadcrumbs li:nth-child(2n) {
  padding: 0 8px;
  img {
    height: 10px;
    width: 10px;
  }
}
.document__bond__contract__list__cards {
  @include max-xl {
    h5,
    .h5 {
      font-size: 0.6rem !important;
    }
    h6,
    .h6 {
      font-size: 0.55rem !important;
    }
  }
}
.tabs-container {
  ::v-deep {
    .v-slide-group__content {
      justify-content: space-between;
    }
  }
}
</style>
